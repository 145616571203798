<template>
    <div>
        <h1>Insurance Dashboard</h1>
    </div>
</template>

<script>
    export default {
        name: 'Insurance'
    };
</script>
